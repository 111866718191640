import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "submit-transaction-details",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#submit-transaction-details",
        "aria-label": "submit transaction details permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Submit transaction details`}</h1>
    <h3 {...{
      "id": "transaction-details---whats-required",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#transaction-details---whats-required",
        "aria-label": "transaction details   whats required permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Transaction details - what's required?`}</h3>
    <p>{`Any required claim or payment attributes are handled within the self-contained UI, such as practice, practitioner, patient and charges. You can also handle success, error & cancel events with an onSuccess, onErrorand onCancel callbacks. We also recommend setting a webhook to obtain transaction status changes post submission.`}</p>
    <p>{`In the most basic mode, you direct users to the Transaction SDK which prompts users to enter all attributes required to process a claim or payment request. Once the transaction is complete, the status and payment reconciliation references are returned which can be recorded in your system. In this mode, users are required to input all attributes of a transaction – even if those details have already been entered in your system.`}</p>
    <p>{`You can pre-populate some or all attributes required to process a transaction.`}</p>
    <p>{`For `}<strong parentName="p">{`Virtual Terminal`}</strong>{` transactions, attributes include:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`platform`}</strong>{`: set to "virtual-terminal"`}</li>
      <li parentName="ul"><strong parentName="li">{`chargeAmount`}</strong>{`: amount to be charged`}</li>
      <li parentName="ul"><strong parentName="li">{`invoiceReference`}</strong>{`: a unique provider defined invoice reference, such as practice management system transaction number or an external accounting system invoice number`}</li>
      <li parentName="ul"><strong parentName="li">{`paymentMethod`}</strong>{`: either a manual entry of payment card (MOTO), SMS payment request or payment request web link`}</li>
      <li parentName="ul"><strong parentName="li">{`patient{mobile}`}</strong>{`: number for SMS payment requests`}</li>
      <li parentName="ul"><strong parentName="li">{`providerNumber`}</strong>{`: to automatically set practice and provider details and settlement instructions`}</li>
    </ul>
    <p>{`For `}<strong parentName="p">{`Funder`}</strong>{` claims and transactions, common attributes include:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`platform`}</strong>{`: set to "funder"`}</li>
      <li parentName="ul"><strong parentName="li">{`funder`}</strong>{`: HICAPS, Medicare, DVA, Patient Funded, icare, Workcover Queensland, etc`}</li>
      <li parentName="ul"><strong parentName="li">{`invoiceReference`}</strong>{`: a unique provider defined invoice reference, such as practice management system transaction number or an external accounting system invoice number`}</li>
      <li parentName="ul"><strong parentName="li">{`providerNumber`}</strong>{`: usually a Medicare issued provider number`}</li>
      <li parentName="ul"><strong parentName="li">{`patient details`}</strong>{`: fist and last name, date of birth`}</li>
      <li parentName="ul"><strong parentName="li">{`items details`}</strong>{`: item codes, service dates and prices`}</li>
    </ul>
    <p><a parentName="p" {...{
        "href": "/sdk/transaction/prepopulating/"
      }}>{`Prepopulating transaction attributes`}</a>{` makes for a much simpler user experience, as it removes double keying of information and users are only prompted for additional attributes or corrections as required.`}</p>
    <h3 {...{
      "id": "transaction-examples-and-pms-playground",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#transaction-examples-and-pms-playground",
        "aria-label": "transaction examples and pms playground permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Transaction examples and PMS Playground`}</h3>
    <p>{`Transaction payload options differ between funders. To simplify your development experience, we’ve created a “PMS Playground” to interactively generate sample transaction payloads. We expose options available for every funder and for Virtual Terminal transactions with the corresponding JSON payload or URL.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/playground.png",
        "alt": null
      }}></img></p>
    <p>{`Access the Playground via `}<a parentName="p" {...{
        "href": "https://playground.medipass.io"
      }}>{`https://playground.medipass.io`}</a>{`.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Note: A valid API key and App ID is required. Playground is set to a non-production environment.`}</p>
    </blockquote>
    <h3 {...{
      "id": "set-a-patient-refid",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#set-a-patient-refid",
        "aria-label": "set a patient refid permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Set a patient refId`}</h3>
    <p>{`A patient `}<inlineCode parentName="p">{`refId`}</inlineCode>{` is your own unique reference to the patient for matching. Medipass will use the `}<inlineCode parentName="p">{`refId`}</inlineCode>{` to store patient details.`}</p>
    <p>{`The first time a transaction is created for a patient, you should send all elements of the patient object.`}</p>
    <p>{`On subsequent transactions you can send only the `}<inlineCode parentName="p">{`refId`}</inlineCode>{` and we will prepopulate patient information. However, if you provide other patient attributes (like first name, last name, etc), we will update those against the patient's `}<inlineCode parentName="p">{`refId`}</inlineCode>{`.`}</p>
    <h4 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h4>
    <p>{`The first time you create a transaction, your patient could look like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`medipassSDK.renderCreateTransaction({
  // ...
  patient: {
    refId: '0001',
    firstName: 'Claudia',
    lastName: 'Connor',
    dob: '1996-08-20',
  },
  // ...
});
`}</code></pre>
    <p>{`On subsequent transactions, your patient could look like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`medipassSDK.renderCreateTransaction({
  // ...
  patient: {
    refId: '0001',
  },
  // ...
});
`}</code></pre>
    <p>{`However, you can still provide the original patient values, and the patient will be updated only when neccessary (when one of the patient values change).`}</p>
    <h3 {...{
      "id": "set-transaction-callbacks",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#set-transaction-callbacks",
        "aria-label": "set transaction callbacks permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Set transaction callbacks`}</h3>
    <p>{`Callbacks are functions that you can provide to a method in the SDK which will give you feedback when an event occurs.`}</p>
    <p>{`They can be useful when you want to record data & attributes at a particular stage of a transaction.`}</p>
    <p>{`You can provide a set of callback events to the second parameter of `}<inlineCode parentName="p">{`renderCreateTransaction`}</inlineCode>{`.`}</p>
    <p><a parentName="p" {...{
        "href": "/sdk/transaction/callbacks"
      }}>{`A list of callbacks provided can be viewed here.`}</a></p>
    <h4 {...{
      "id": "example-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#example-1",
        "aria-label": "example 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import medipassSDK from '@medipass/partner-sdk';

medipassSDK.renderCreateTransaction(
  {
    // ... transaction attributes
  },
  {
    onSuccess: function(transaction) {
      console.log(transaction);
      // -> { _id: '123', created: '2020-06-22T23:18:30.329', items: [...], claims: [...], ... }
    },
    onError: function(error) {
      console.log(error);
      // -> { message: 'An error occured.' }
    },
    onCancel: function() {
      console.log('Transaction cancelled.');
    },
    onCloseModal: function({ transaction, error, status }) {
      if (status === 'success') {
        console.log(transaction);
        // -> { _id: '123', created: '2020-06-22T23:18:30.329', items: [...], claims: [...], ... }
      } else if (status === 'error') {
        console.log(error);
        // -> { message: 'An error occured.' }
      } else if (status === 'cancelled') {
        console.log('Transaction cancelled.');
      }
    },
    onTransactionCreated: function(transaction) {
      console.log('Transaction created', transaction);
    },
    onTransactionUpdated: function(transaction) {
      console.log('Transaction updated', transaction);
    },
  }
);
`}</code></pre>
    <h3 {...{
      "id": "set-transaction-webhooks",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#set-transaction-webhooks",
        "aria-label": "set transaction webhooks permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Set transaction webhooks`}</h3>
    <p>{`Whilst the majority of transactions are processed in real-time with an immediate outcome, some transaction outcomes may be delayed by hours or days. For example, some Medicare transactions may require manual review with an outcome 2-3 days post submission. Likewise, a Virtual Terminal transaction with a patient SMS request may take minutes or hours for action.`}</p>
    <p>{`You can get transaction updates in two ways:`}</p>
    <ul>
      <li parentName="ul">{`Set a unique transaction webhook; or`}</li>
      <li parentName="ul">{`Periodically poll the transaction status using our `}<a parentName="li" {...{
          "href": "/sdk/sdk-reference/transactions/#sdktransactionsgetbusinesstransactionoptions"
        }}>{`'Get Transaction' method`}</a>{` in the SDK.`}</li>
    </ul>
    <p><strong parentName="p">{`We recommend using the webhook method.`}</strong></p>
    <p><a parentName="p" {...{
        "href": "/sdk/transaction/webhooks"
      }}>{`A list of webhooks provided can be viewed here.`}</a></p>
    <h4 {...{
      "id": "example-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#example-2",
        "aria-label": "example 2 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example`}</h4>
    <p>{`To set a webhook, use the `}<inlineCode parentName="p">{`webhooks`}</inlineCode>{` attribute and provide:`}</p>
    <ul>
      <li parentName="ul">{`URL (usually transaction specific)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/sdk/transaction/webhooks"
        }}>{`webhook event`}</a></li>
      <li parentName="ul">{`method: POST/GET/PUT/DELETE`}</li>
      <li parentName="ul">{`Any required headers (your transaction specific ID or authentication header could be set here)`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`medipassTransactionSDK.renderCreateTransaction({
  // ...
  webhooks: [
    {
      url: 'https://your-site.com/transactions/your-transactionId/success',
      event: 'healthFundApprovedInvoice',
      method: 'POST',
      headers: { sessionKey: 'Hello world' }
    },
    {
      url: 'https://your-site.com/transactions/your-transactionId/failure',
      event: 'healthFundRejectedInvoice',
      method: 'POST',
      headers: { sessionKey: 'Hello world' }
    }
  ]
  // ...
})
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      